import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/scss/style.scss";

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

const App = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Suspense fallback={<div className="pt-3 text-center">Loading</div>}>
        <Routes>
          <Route path="/confirm/index/id/:id" element={<DefaultLayout />} />
          <Route
            path="/confirm/index/id/:id/group/:group"
            element={<DefaultLayout />}
          />
          <Route path="/" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
